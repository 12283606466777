import { render, staticRenderFns } from "./lifterFaultResolvent.vue?vue&type=template&id=558c6165&scoped=true&"
import script from "./lifterFaultResolvent.vue?vue&type=script&lang=js&"
export * from "./lifterFaultResolvent.vue?vue&type=script&lang=js&"
import style0 from "./lifterFaultResolvent.vue?vue&type=style&index=0&id=558c6165&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "558c6165",
  null
  
)

export default component.exports